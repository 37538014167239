@import "~@cpsq/ui-components/src/assets/stylesheets/utilities/colours";

.respondent-results-table{
  .MuiPaper-root {
    border: 2px $alto solid;
    overflow-x: hidden;
    border-radius: 8px;
  }

  .MuiTableFooter-root td.MuiTableCell-root:last-child{
    border-bottom: none;
  }

  .MuiIconButton-root[data-testid="Filter Table-iconButton"],
  .MuiIconButton-root[data-testid="View Columns-iconButton"],
  .MuiIconButton-root[data-testid="Download CSV-iconButton"] {
    border-radius: 0;

    span.MuiIconButton-label::after {
      font-family: "Source Sans Pro";
      font-size: 15px;
      color: black;
      padding-left: 5px;
      height: "auto !important";
    }
  }

  .MuiIconButton-root[data-testid="Filter Table-iconButton"] {
    span.MuiIconButton-label::after {
      content: "Filter";
    }
  }

  .MuiIconButton-root[data-testid="View Columns-iconButton"] {
    span.MuiIconButton-label::after {
      content: "Styles";
    }
  }

  .MuiIconButton-root[data-testid="Download CSV-iconButton"] {
    span.MuiIconButton-label::after {
      content: "Download as .CSV";
    }
  }

  // Fixes horizontal scroll XT-3411
  div div:nth-child(3) {
    overflow: visible;
  }

  @media (max-width:767px) {
    div div:nth-child(3) {
      overflow-x: auto;
    }
  }

  table {
    td,
    th:not(:first-child) {
      > span {
        justify-content: center;
      }
    }
    tbody tr {
      td {
        box-sizing: border-box;
        border-left: 1px solid $alto;
      }
      td:last-child {
        border-right: 1px solid $alto;
      }
      td:not(:first-child) {
        text-align: center;
      }
      td:first-child {
        word-break: break-word;
      }
    }
  }
}
