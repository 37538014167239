.group-confirmation {
  h2 {
    margin-bottom: 5px;
  }

  img {
    display: block;
    margin: 65px auto;
  }

  button {
    &:not(:last-child) {
      margin-right: 15px;
    }
    @media screen and (max-width: 768px) {
      width: 75%;
      &:not(:last-child) {
        margin: 0 0 10px 0;
      }
    }
  }
}
