@import "~@cpsq/ui-components/src/assets/stylesheets/utilities/colours";

.respondents-table {
  .invite-sending {
    color: $silver-chalice;

    svg {
      margin-right: 5px;
    }
  }
}