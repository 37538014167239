@import "~@cpsq/ui-components/src/assets/stylesheets/utilities/colours";

.centres {
  > header {
    display: flex;
    flex-wrap: wrap;
    margin-top: -60px;
    margin-bottom: 50px;
    padding-top: 30px;
    padding-bottom: 30px;

    h1 {
      margin-bottom: 10px;
    }

    h2 {
      color: $dove-grey;
    }
  }
}