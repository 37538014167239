@import "~@cpsq/ui-components/src/assets/stylesheets/utilities/colours";
@import "~@cpsq/ui-components/src/assets/stylesheets/utilities/mixins";

.centre-dashboard {
  > header {
    margin-top: -60px;
    margin-bottom: 32px;
    padding-top: 40px;
    padding-bottom: 40px;
    align-items: flex-start;
    display: flex;

    .groups-filter {
      position: relative;

      input[type="text"] {
        @include placeholder($scorpion);
        background-color: $alto;
        border: 0 none;
        color: $scorpion;
        text-indent: 24px;
        @media screen and (max-width: 576px) {
          width: 100%;
        }
      }

      svg {
        color: $grey;
        margin: auto;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 12px;
      }
    }

    .button {
      margin-left: auto;
    }

    .section-heading {
      margin-bottom: 10px;
    }

    .section-sub-heading {
      color: $dove-grey;
      margin-bottom: 32px;
    }
  }
}