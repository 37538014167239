@import "~@cpsq/ui-components/src/assets/stylesheets/utilities/colours";

body {
  &.new-centre {
    background-color: $alabaster;

    .centre-created {
      h2 {
        margin-bottom: 5px;
      }

      img {
        display: block;
        margin: 65px auto;
      }
    }

    .error-message {
      color: $red-1;
    }
  }
}