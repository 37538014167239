/* can this gap be generically defined? */

.confirmation-text {
  margin-bottom: 40px;
}

.confirmation-image {
  height: 158px;
  width: 158px;
  display: block;
  margin-bottom: 40px;
}