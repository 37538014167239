@import "~@cpsq/ui-components/src/assets/stylesheets/utilities/colours";

.ca-dropdown-option {
  p {
    font-size: 12px;
    margin-bottom: 0;
  }
}

.options-heading {
  color: $dove-grey;
}

.pill-selected-group {
  background-color: $alto;
  border-radius: 16px;
  display: inline-block;
  overflow: hidden;
  padding: 2px 0 3px 16px;

  &:not(:last-child) {
    margin-right: 16px;
  }

  p {
    margin-bottom: 0;
  }

  .remove-btn {
    background-color: transparent;
    border: none;
    cursor: pointer;
    opacity: 0.6;
    vertical-align: text-bottom;

    &:hover {
      opacity: 1;
    }
  }

  &.disabled {
    opacity: 0.6;
    pointer-events: none;
  }
}