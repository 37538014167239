@import "~@cpsq/ui-components/src/assets/stylesheets/utilities/colours";

.container {
  display: flex;
  flex-direction: column;
  min-width: 500px;
  width: 80%;
  height: 300px;
  margin: auto;
  background-color: $white;
  border: 1px solid $silver;
  border-radius: 8px;
  padding: 35px 32px;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.04);

  @media (max-width: 768px) {
    margin-top: 143px;
  }
}
.api-key-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  @media (pointer: coarse) {
    svg {
    width: 2.5em !important; 
    height: 2.5em
  }
}
}

.infoText {
  text-align: center;
}

.api-key-action-btn {
  width: fit-content;
}

.api-key-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.api-key-input-wrapper {
  border: solid 1px $alto;
  border-radius: 7px;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 8 0;
}

.api-key-input {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
  border: none;
  overflow-x: hidden;
  flex: 9;
}

.visibility-button {
  color: $grey;

  &:hover {
    color: $woodsmoke;
  }
}

.specification-button {
  width: fit-content;
  align-self: flex-end;
}

@media screen and (max-width: 500px) {
  .container {
    width: 100%;
    min-width: fit-content;
    padding: 20px;
  }

  .api-key-wrapper {
    flex-wrap: wrap;
  }

  .visibility-button {
    min-width: 32px !important;
    width: 32px;
    padding: 0 !important;
  }

  .api-key-input-wrapper {
    flex: 1 0 100%;
    justify-content: space-evenly;
  }
}

