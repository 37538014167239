@import "~@cpsq/ui-components/src/assets/stylesheets/utilities/colours";

.group-dashboard {
  .breadcrumbs {
    width: 100%;
  }

  > header {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;
    padding-top: 30px;
    padding-bottom: 30px;

    h1 {
      margin-bottom: 50px;
    }
  }

  .dropdown {
    display: inline-block;

    .archive {
      color: $punch;
    }

    .unarchive {
      color: $sushi;
    }
  }

  .group-statistics {
    margin-bottom: 40px;

    li {
      display: inline-block;

      &:not(:last-child) {
        margin-right: 50px;
      }

      span {
        display: block;

        &:first-child {
          color: rgba($black, 0.59);
          margin-bottom: 2px;
        }
      }
    }
  }

  .tabs-container {
    .tabs-header {
      margin-bottom: 40px;
    }

    .tabs-navigation {
      .button {
        &:not(:last-child) {
          margin-right: 16px;
        }
      }
    }
  }
}

.group-dashboard-modal {
  background-color: $white;
  border-radius: 8px;
  margin-right: -50%;
  outline: none;
  overflow: auto;
  padding: 20px;
  position: absolute;
  top: 50%;
  bottom: auto;
  left: 50%;
  right: auto;
  text-align: center;
  transform: translate(-50%, -50%);

  h2 {
    margin-bottom: 10px;
  }

  .button {
    &:not(:last-child) {
      margin-right: 30px;
    }
  }

  .modal-body {
    margin: 32px 0;
  }

  .modal-header {
    background-color: $alto;
    margin: -20px -20px 0 -20px;
    padding: 20px;
  }
}

.group-dashboard-modal-overlay {
  background-color: rgba($black, 0.77);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}