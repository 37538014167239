@import
  "~susy/sass/susy",
  "~@cpsq/ui-components/src/assets/stylesheets/vendor/susy";

.centre-settings {
  @include susy-at($mobile) {
    .tabs-navigation {
      button {
        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }
    }
  }
}
