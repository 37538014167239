@import "~@cpsq/ui-components/src/assets/stylesheets/utilities/colours";

.test-usage {
  .hero {
    margin-bottom: 50px;
    padding-top: 40px;
    padding-bottom: 40px;

    > span {
      color: rgba($black, 0.59);
      display: block;
      margin-bottom: 5px;
    }
  }

  .tabs-navigation {
    margin-bottom: 60px;

    button {
      margin-top: 15px;
      &:not(:last-child) {
        margin-right: 15px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    table {
      tbody {
        tr {
          td {
            padding: 0;
            max-width: 10%;
            table {
              tbody {
                tr {
                  td:nth-child(1) {
                    padding: 0 0 0 5px;
                  }
                  td:nth-child(n + 2) {
                    text-align: center;
                    padding: 0;
                  }
                }
              }
            }
          }
        }
        td:nth-child(2) {
          padding: 0 0 0 5px;
        }
        td:nth-child(n + 3) {
          text-align: center;
          padding: 0;
        }
      }

      thead {
        tr {
          th {
            padding: 0;
            position: static;
          }
        }
      }
    }
  }
}
