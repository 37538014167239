@import "~susy/sass/susy",
  "~@cpsq/ui-components/src/assets/stylesheets/vendor/susy";

.group-dashboard {
  @include susy-at($mobile) {
    > header {
      margin-top: -44px;

      .column-one,
      .column-two {
        width: span(4);
      }

      .column-one {
        h3 {
          overflow-wrap: break-word;
        }
      }

      .column-two {
        button {
          margin-bottom: 20px;
          width: 100%;
        }
      }
    }

    .group-statistics {
      li {
        min-width: 154.5px;
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }

    .respondents-table {
      tbody {
        tr {
          > td {
            position: static;
          }
        }
      }
    }

    .dropdown {
      margin-bottom: 32px;
    }

    .button {
      display: block;
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }

  @include susy-at($mobile-and-tablet) {
    > header {
      flex-wrap: wrap;

      .column-one {
        margin-bottom: 40px;
        h3 {
          overflow-wrap: break-word;
        }
      }
    }

    .tabs-navigation {
      margin-bottom: 20px;
      .respondent-count {
        margin-left: 15px;
      }
    }
  }

  @include susy-at($tablet) {
    > header {
      margin-top: -60px;

      .column-one,
      .column-two {
        width: span(8);
      }

      .column-one {
        h3 {
          overflow-wrap: break-word;
        }
      }

      .column-two {
        button {
          margin-right: 20px;
        }
      }
    }

    .button.senary {
      margin-right: 15px;
    }

    .tabs-navigation {
      margin-bottom: 20px;
    }
  }

  @include susy-at($desktop) {
    > header {
      .column-one {
        width: span(8);
        h3 {
          overflow-wrap: break-word;
        }
      }

      .column-two {
        align-items: flex-end;
        display: flex;
        flex-direction: column;
        width: span(4);

        button {
          margin-right: 0;
          margin-bottom: 20px;
        }
      }
    }

    .dropdown {
      position: relative;
      left: -20px;
    }

    .tabs-header {
      display: flex;
      justify-content: space-between;
    }

    .button.senary {
      margin-right: 15px;
    }

    .tabs-navigation {
      margin-bottom: 0;
      .respondent-count {
        margin-left: 15px;
      }
    }
  }
}
