@import "~@cpsq/ui-components/src/assets/stylesheets/utilities/mixins";

.add-respondents {
  .add-respondents-form {
    &.has-errors {
      label {
        @include safely-hide;
      }
    }
  }

  .feedback-message {
    margin-bottom: 40px;
  }
}
