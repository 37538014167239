@import "~@cpsq/ui-components/src/assets/stylesheets/utilities/colours";
@import "~@cpsq/ui-components/src/assets/stylesheets/utilities/mixins";

.groups-filter {
  position: relative;
  margin-bottom: 25px;

  input[type="text"] {
    @include placeholder($scorpion);
    background-color: $alto;
    border: 0 none;
    color: $scorpion;
    width: 300px;
    text-indent: 24px;
  }

  svg {
    color: $grey;
    margin: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 12px;
  }
}

.manage-users-delete-user-modal {
  background-color: $white;
  border-radius: 8px;
  margin-right: -50%;
  outline: none;
  overflow: auto;
  padding: 20px;
  position: absolute;
  top: 50%;
  bottom: auto;
  left: 50%;
  right: auto;
  text-align: center;
  transform: translate(-50%, -50%);

  h2 {
    margin-bottom: 10px;
  }

  .button {
    &:not(:last-child) {
      margin-right: 30px;
    }
  }

  .modal-body {
    margin: 32px 0;
  }

  .modal-header {
    background-color: $alto;
    margin: -20px -20px 0 -20px;
    padding: 20px;
  }
}

.manage-users-delete-user-modal-overlay {
  background-color: rgba($black, 0.77);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.centre-settings {
  .hero {
    justify-content: space-between;
    margin-top: -62px;
    margin-bottom: 60px;
    padding-top: 60px;
    padding-bottom: 40px;

    h2, h3 {
      display: block;
    }

    h2 {
      color: $scorpion;
    }

    h3 {
      margin-bottom: 5px;
    }

    .centre-details, .centre-logo {
      display: inline-block;
      vertical-align: top;
    }

    .centre-logo {
      align-items: center;
      background-color: $alto;
      border-radius: 80px;
      color: $white;
      display: inline-flex;
      height: 160px;
      justify-content: center;
      margin-right: 40px;
      width: 160px;
    }
  }

  .centre-details {
    h2 {
      margin-bottom: 20px;
    }

    dl {
      > div {
        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }
  }

  .tabs-navigation {
    margin-bottom: 60px;

    button {
      &:not(:last-child) {
        margin-right: 15px;
      }
    }
  }
}