@import
  "~susy/sass/susy",
  "~@cpsq/ui-components/src/assets/stylesheets/vendor/susy";

.centres {
  > header {
    @include susy-at($mobile) {
      flex-wrap: wrap;

      .column-one {
        margin-bottom: 20px;
      }

      .column-one, .column-two {
        width: span(4);
      }
    }

    @include susy-at($tablet) {
      .column-one {
        width: span(6);
      }

      .column-two {
        text-align: right;
        width: span(2);
      }
    }

    @include susy-at($desktop) {
      .column-one {
        width: span(10);
      }

      .column-two {
        text-align: right;
        width: span(2);
      }
    }
  }
}