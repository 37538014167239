@import "node_modules/susy/sass/susy";

@import "~@cpsq/ui-components/src/assets/stylesheets/vendor/susy";

[role="dialog"] {
  max-width: 600px;
  min-width: 300px;
  line-height: 1.2;

  .modal-close {
    text-align: right;
    .icon {
      cursor: pointer;
    }
  }
  .modal-body {
    padding: 0 25px;
    .preview-subject-line {
      margin-bottom: 40px;
    }

    .preview-from-line {
      margin-bottom: 40px;
    }

    .preview-details {
      margin-bottom: 20px;
    }

    .preview-link {
      margin-bottom: 20px;
      color: -webkit-link;
      text-decoration: underline;
    }
  }
}

.button-container {
  @include susy-at($mobile) {
    flex-direction: column-reverse;

    > .button {
      width: 100%;
      text-align: center;
      margin-left: 0;
    }
    > .button:not(:last-child) {
      margin-top: 10px;
    }
    > .button:first-child {
      margin-top: 15px;
    }
  }
}
.checkbox-small {
  margin-top: -14px;
  margin-left: 5px;
}
