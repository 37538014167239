@import "~@cpsq/ui-components/src/assets/stylesheets/utilities/colours";

body {
  &.welcome {
    background-color: $gallery;
  }

  .welcome-card {
    display: flex;
    justify-content: space-between;
    margin-top: -60px;
    padding-top: 65px;

    button {
      margin-right: 48px;
      @media screen and (max-width: 400px) {
        margin-bottom: 10px;
      }
    }

    .logo-container {
      margin-bottom: 55px;
    }
  }
}
