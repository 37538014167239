@import
  "~@cpsq/ui-components/src/assets/stylesheets/utilities/colours",
  "~@cpsq/ui-components/src/assets/stylesheets/components/react-select";

canvas {
  padding: 10px;
  margin-bottom: 50px;
}

.chart-container {
  min-height: 300px;
}