@import "~@cpsq/ui-components/src/assets/stylesheets/utilities/colours";

.group-results {
  > header {
    margin-top: -60px;
    margin-bottom: 32px;
    padding-top: 40px;
    padding-bottom: 10px;

    .section-heading {
      margin-bottom: 10px;
    }
  
    .section-sub-heading {
      color: $dove-grey;
      margin-bottom: 32px;
    }
  }
}

// TODO: move margins to central utils package
$margin-base: 8px;

.margin-top-xs{
  margin-top: $margin-base;
}
.margin-top-sm{
  margin-top: $margin-base*2;
}
.margin-top-md{
  margin-top: $margin-base*4;
}
.margin-top-lg{
  margin-top: $margin-base*8;
}

.margin-bot-xs{
  margin-bottom: $margin-base/2;
}
.margin-bot-sm{
  margin-bottom: $margin-base*2;
}
.margin-bot-md{
  margin-bottom: $margin-base*4;
}
.margin-bot-lg{
  margin-bottom: $margin-base*8;
}