@import "~@cpsq/ui-components/src/assets/stylesheets/utilities/colours";

.welcome {
  
  .products {
    display: flex;
  }

  .product-card {
    border: 1px solid $alto;
    border-radius: 8px;
    cursor: pointer;
    padding: 30px;

    h1 {
      margin-bottom: 10px;
    }

    svg {
      margin-bottom: 30px;
    }
  }
}